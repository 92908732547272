<template>
  <div class="mx-auto w-full max-w-screen-2xl">
    <div class="flex h-full flex-col items-center md:flex-row">
      <WelcomeBestSellers
        class="bg-grays-lighter hidden h-full w-full md:flex"
      />
      <div
        class="h-48 w-full bg-[url('/img/welcome/welcome-vp.png')] bg-cover bg-center md:hidden"
      ></div>
      <WelcomeOtp class="w-full grow py-10" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { definePageMeta, onMounted } from '#imports'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'

const { reset: resetGuest } = useGuestStore()
const { reset: clearCart } = useGuestCartStore()
onMounted(() => {
  resetGuest()
  clearCart()
})

definePageMeta({
  showSearch: false,
  showNav: false,
  hideCart: true,
  unauthorized: true,
  hideLanguages: true,
  skipMiddleware: true,
})
</script>
