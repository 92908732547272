<template>
  <div class="flex flex-col items-center justify-center space-y-12">
    <h2 class="text-xl font-semibold">Our Bestsellers</h2>
    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="flex h-44 w-44 flex-col items-center justify-center space-y-4 rounded bg-white px-4 lg:h-52 lg:w-52 xl:h-64 xl:w-64"
      >
        <div class="flex h-20 flex-col justify-center xl:h-24">
          <NuxtImg
            :src="card.imgSrc"
            :alt="card.altText"
            class="text-grays-darkest"
          />
        </div>
        <h3
          class="text-grays-darkest whitespace-nowrap text-center font-semibold lg:text-lg"
        >
          {{ card.title }}
        </h3>
        <span class="text-grays-dark">{{ card.productCode }}</span>
      </div>
    </div>
    <span>Available in Prescription and Non-Prescription</span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const cards = ref([
  {
    imgSrc: '/img/welcome/welcome-vision1.png',
    altText: 'Z87.1 safety glasses',
    title: 'Square Glasses',
    productCode: '#2020123',
  },
  {
    imgSrc: '/img/welcome/welcome-safety2.png',
    altText: 'Z87.1 safety glasses',
    title: 'Z87.1 Safety Glasses',
    productCode: '#749121',
  },
  {
    imgSrc: '/img/welcome/welcome-vision2.png',
    altText: 'Square glasses',
    title: 'Square Glasses',
    productCode: '#126016',
  },
  {
    imgSrc: '/img/welcome/welcome-safety1.png',
    altText: 'Square glasses',
    title: 'Z87.1 Safety Glasses',
    productCode: '#748621',
  },
])
</script>
