<template>
  <OtpVerify
    ref="otpRef"
    :is-phone="isValidNumber"
    :force-loading="loading"
    @verified="($event) => handleVerified($event)"
  >
    <div
      class="text-grays-darkest flex w-full max-w-lg flex-col space-y-7 md:items-center"
    >
      <div class="text-center">
        <h1 class="text-2xl font-semibold">Claim your eyewear benefit now</h1>
        <p class="mt-4 text-sm">
          Enter your mobile number or email below to send a one-time
          verification code associated with your account. Message and data rates
          may apply.
        </p>
      </div>
      <label class="relative w-full">
        <span class="text-grays-darkest text-sm font-semibold tracking-wide"
          >Mobile number or email</span
        >
        <input
          v-model="contact"
          type="email"
          placeholder="Enter mobile number or email"
          class="border-grays-light placeholder:text-grays-medium mt-1.5 block h-14 w-full rounded-lg border text-base placeholder:text-sm"
        />
      </label>
      <div class="flex self-start">
        <ZnCheckbox v-model="agree" tabindex="0" />
        <span class="ml-2">
          I have read and agree to the
          <nuxt-link
            to="/terms-of-use/employee"
            target="_blank"
            class="underline underline-offset-2"
            >Terms of Use</nuxt-link
          >
          and
          <nuxt-link
            to="https://www.zennioptical.com/privacy-policy"
            target="_blank"
            class="underline underline-offset-2"
            >Privacy Policy</nuxt-link
          >.
        </span>
      </div>
      <ZnButton
        tabindex="0"
        :disabled="!agree"
        :class="`w-full text-center ${
          !agree ? 'bg-grays-light text-white' : ''
        }`"
        @click="handleSetContactAndSendCode()"
      >
        <span v-if="!otpRef?.loading">Submit</span>
        <ZenniIconsIconLoading v-else class="text-white" />
      </ZnButton>
    </div>
  </OtpVerify>
</template>

<script setup lang="ts">
import { jwtDecode } from 'jwt-decode'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { toast } from '@/components/Toast'
import OtpVerify from '@/components/OtpVerify/index.vue'
import { useGift } from '@/composables/api/useGift'
import { ref, computed, useRouter } from '#imports'
import { useGuestStore } from '~/store/guest'

const otpRef = ref<InstanceType<typeof OtpVerify>>()
const agree = ref<boolean>()
const loading = ref<boolean>(false)
const guestStore = useGuestStore()

const router = useRouter()
const { findGift } = useGift()
const handleVerified = async (token: string) => {
  if (token) {
    loading.value = true
    const { email } = jwtDecode<{ email: string }>(token)

    const { data, error } = await findGift(email ?? '')
    if (error.value) {
      toast.open({
        type: 'error',
        title: 'Error occured',
        content:
          'Failed to load gift information. If the issue persists, contact support.',
      })
      loading.value = false
    } else if (data.value) {
      await router.push({
        path: '/',
        query: {
          gift: data.value.id,
        },
      })

      guestStore.setLoggedInToken(token)
    }
  }
}

const contact = ref<string>('')

const isValidNumber = computed<boolean>(() => {
  const phoneNumber = parsePhoneNumberFromString(contact.value, 'US')

  return phoneNumber?.isValid() ?? false
})

const handleSetContactAndSendCode = () => {
  if (otpRef.value) {
    otpRef.value.contact = contact.value
    otpRef.value.handleSendCode()
  }
}
</script>
